import React, { useState, useEffect } from "react";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { connect } from "react-redux";
import navigate from "~/components/navigate";
import Layout from "~/components/layout";
import SEO from "~/components/seo";
import Forgot from "~/components/auth/forgot";
import { getParameterByName } from "~/utils/app-helpers";

function ForgotPage(props) {
  const paths = props.pageContext.paths;
  const i18n = props.i18n;
  const error = getParameterByName("err");
  const [section, setSection] = useState();

  const [logged, setLoginStatus] = useState(null);

  const errorMsg = (error) => {
    switch (error) {
      case "invalid_token":
        return <Trans>Invalid token, please request a new one</Trans>;

      case "token_not_found":
        return (
          <Trans>
            Looks like some error happened, please request a new recovery link
          </Trans>
        );

      case "token_expired":
        return (
          <Trans>
            Recovery link expired, please request a new recovery link
          </Trans>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (props.auth.email) {
      navigate("/", i18n.language);
      return () => {
        return false;
      };
    } else setLoginStatus(false);

    switch (section) {
      case "login":
        navigate("/login", i18n.language);
        break;

      default:
        return () => {
          return false;
        };
    }

    return () => {
      return false;
    };
  }, [section, i18n.language, props.auth.email]);

  if (logged === null) {
    return null;
  }

  return (
    <Layout paths={paths} hideFooter={true}>
      <SEO title={i18n._(t`Lost your password?`)} location={paths} />

      <div className="flex  md:items-center md:margin-auto justify-center h-mobile md:h-desktop">
        <div className="px-4 pt-8 md:pt-0 lg:max-w-md">
          <Forgot changeSection={setSection} errorMsg={errorMsg(error)} />
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = ({ auth }) => {
  return {
    auth
  };
};

export default withI18n()(connect(mapStateToProps)(ForgotPage));
